@import url("https://use.typekit.net/nia0sjo.css");
body {
    background-color: #202020;
    display: flex;
    flex-direction: column;
    align-items: center;
}

body, p, h1, h2, h3, h4, h5, h6 {
    color: white;
    font-family: 'Futura PT', Verdana, sans-serif;
}

a, a:visited {
    color: #009ee3;
}

a:hover {
    color: #8cd6f6;
}

h1, h2, h3, h4, h5, h6, .subtitle, .eventDate, .startTime {
    color: #a39a8e;
    font-weight: normal;
}

.startTime {
    font-size: 10pt;
}

h1 {
    font-size: 32pt;
}

h2, h3 {
    margin-bottom: 0;
}

h2 {
    font-size: 24pt;
}

h3 {
    font-size: 16pt;
}

.subtitle {
    font-size: 10pt;
}

h1 + .subtitle {
    margin-top: -28px;
}

.header-logo, .content {
    width: 928px;
    max-width: 928px;
}
.content {
    border: 1px solid #a39a8e;
    background-color: black;
}

.content .doc
{
    padding: 16px;
}

.video-link  {
    text-align: center;
}

.splash * {
    line-height: 0;
    margin: 0;
}

.splash.content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.splash.content > * {
    flex: 1 1 auto;
}
.splash img {
    max-width: 928px;
}

.doc + .row {
    border-top: 1px solid #a39a8e;
    margin-top: 16px;
}

.doc-banner {
    margin: 0 -15.5px;
}

.row + .doc {
    border-top: 1px solid #a39a8e;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: space-between;
    height: 232px;
}

    .row > * {
        flex: 1 1 auto;
    }
    .row img {
        width: 232px;
        max-width: 232px;
    }
